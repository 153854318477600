import { ShopUp } from '@shopuptech/api-client'
import { sha256 } from 'js-sha256'

const extractBody = async (response) => {
  const contentType = response.headers.get('content-type') || ''

  if (/application\/json/.test(contentType)) {
    const json = await response.json()

    if (json.statusCode === 400 && !json.error) {
      return {
        ...json,
        error: {
          ...json,
        },
      }
    }
    return json
  }

  if (!contentType || /^text\/|charset=utf-8$/.test(contentType)) {
    return response.text()
  }

  return response.arrayBuffer()
}

function generateId(secret, duration) {
  const time = Date.now()
  const timeSlot = Math.floor(time / 1000 / duration)

  const message = JSON.stringify({ t: timeSlot })

  const signature = sha256.hmac.update(secret, message)

  return signature.hex()
}

export const getShopUpLiteApiClient = ({ baseUrl = '/api' }) => {
  const apiClient = ShopUp({
    baseUrl,
    request: {
      fetch: window.fetch,
    },
    response: {
      body: extractBody,
    },
  })

  apiClient.requestHook.before((options) => {
    options.headers = {
      ...options.headers,
      'X-User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    if (options.url.includes('/auth/v0/'))
      options.headers = {
        ...options.headers,
        'Cache-Control': 'no-cache',
        'X-Request-Signature': generateId(
          process.env.REACT_APP_HASH_SECRET ?? '',
          2
        ),
      }
    if (options.filter && typeof options.filter === 'object') {
      options.filter = Object.keys(options.filter).reduce((filter, key) => {
        filter[`filter[${key}]`] = options.filter[key]
        return filter
      }, {})
    }

    if (options.fields && typeof options.fields === 'object') {
      options.fields = Object.keys(options.fields).reduce((fields, key) => {
        fields[`fields[${key}]`] = options.fields[key]
        return fields
      }, {})
    }
  })

  const api = apiClient.request

  return { apiClient, api }
}
